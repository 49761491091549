import { FallbackProps } from 'react-error-boundary';
import React from 'react';
import dynamic from 'next/dynamic';
import { Image, LazyLoadComponent } from '@honeycomb/ui-core';
import { UnexpextedErrorProps } from '@honeycomb/ui';

// Function that takes a correlationId and returns a render function for the ErrorBoundary
export function errorFallback(
    correlationId?: string
): (
    props: FallbackProps
) => React.ReactElement<unknown, string | React.FunctionComponent | typeof React.Component> | null {
    const UnexpectedError = dynamic<UnexpextedErrorProps>(
        () => import('./UnexpectedError').then((module) => module.UnexpectedError),
        {
            ssr: false,
        }
    ) as React.FunctionComponent<UnexpextedErrorProps>;

    function ErrorFunction({ error, resetErrorBoundary }: FallbackProps) {
        return (
            <LazyLoadComponent
                intersectionProps={{ triggerOnce: true }}
                child={
                    <UnexpectedError
                        correlationId={correlationId}
                        error={error}
                        resetErrorBoundary={resetErrorBoundary}
                    >
                        <Image src={{ xs: '/images/ice-cream-small.png', m: '/images/ice-cream.png' }} />
                    </UnexpectedError>
                }
            />
        );
    }
    return ErrorFunction;
}

import { NextPage } from 'next';
import Head from 'next/head';
import type { AppProps } from 'next/app';
import getConfig from 'next/config';
import { ReactElement, ReactNode, useEffect, useMemo } from 'react';
import {
    ApolloProvider,
    useApollo,
    initialAppState,
    GlobalProvider,
    usePageRouting,
    setFeatureFlags,
    parseBoolean,
} from '@honeycomb/data';
import {
    getDataDogConfigSettings,
    loadAndInitDataDogRum,
    trackPageViewClient,
    trackPageViewServer,
} from '@honeycomb/tracking';
import {
    GoogleTagManager,
    GoogleTagManagerNoScript,
    Layout,
    Platform195,
    Platform195Brand,
    Platform195Props,
    Webtrends,
    isMobileAppRequest,
    useMountEffect,
    Modal,
} from '@honeycomb/ui';
import {
    HoneycombThemeProvider,
    BreakpointProvider,
    LazyImageProvider,
    TRAVEL_REPUBLIC_THEME_OVERRIDES,
    TRAVEL_REPUBLIC_CUSTOM_STYLE_PROPS,
    Styler,
} from '@honeycomb/ui-core';
import { addRouterEventListener, priceDisplayModeToggleRouting, searchCreatedRouting } from '@honeycomb/routing';

import { ErrorBoundary } from 'react-error-boundary';
import { v4 } from 'uuid';
import dynamic from 'next/dynamic';
import '../styles/F37Judge.css';
import '../styles/Manrope.css';
import { errorFallback } from '../components/errorFallback';
import { Masthead } from '../components/Masthead';

type NextPageWithLayout = NextPage & {
    getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout;
};

export type GetLayout<P> = (page: React.ReactElement, props: P) => ReactNode;

const { mockingEnabled } = getConfig()?.publicRuntimeConfig || { mockingEnabled: '' };
if (mockingEnabled === 'true') {
    import('@honeycomb/data').then((mod) => mod.mockData());
}

const platform195Props: Platform195Props = {
    sizeArray: [
        {
            screenSize: [1024, 0],
            adSize: [
                [970, 250],
                [728, 90],
            ],
        },
        { screenSize: [768, 0], adSize: [[728, 90]] },
        {
            screenSize: [360, 0],
            adSize: [
                [300, 250],
                [320, 100],
            ],
        },
    ],
    adSlots: [
        {
            name: 'HO_HT_SRP/SRPAD1',
            sizes: [
                [320, 100],
                [728, 90],
            ],
            id: 'div-gpt-ad-1934534-16',
        },
        {
            name: 'HO_HT_SRP/SRPAD2',
            sizes: [
                [320, 100],
                [728, 90],
            ],
            id: 'div-gpt-ad-1934534-17',
        },
        {
            name: 'DEST/Hero',
            sizes: [
                [970, 250],
                [728, 90],
                [300, 250],
            ],
            id: 'div-gpt-ad-1942499-1',
        },
    ],
    platform195Brand: Platform195Brand.TravelRepublic,
};

const Footer = dynamic(() => import('../components/Footer').then((module) => module.Footer), {
    ssr: false,
}) as React.FunctionComponent;

const main = (children: React.ReactElement) => {
    // Prepare a custom "main" component which will render the curved ends which sit immediately above the footer.
    // These are applied by two absolutely positioned svgs.
    return (
        <Styler component="main" pos="relative">
            {children}
            <Styler
                component="svg"
                viewBox="0 0 28 28"
                xmlns="http://www.w3.org/2000/svg"
                w={28}
                h={28}
                pos="absolute"
                bottom={0}
                left={0}
                zIndex={1}
                fill="primary.tint.700"
            >
                <path d="M28 28C12.536 28 0 15.464 0 0V28H28Z" />
            </Styler>
            <Styler
                component="svg"
                viewBox="0 0 28 28"
                xmlns="http://www.w3.org/2000/svg"
                w={28}
                h={28}
                pos="absolute"
                bottom={0}
                right={0}
                zIndex={1}
                fill="primary.tint.700"
            >
                <path d="M28 0C28 15.464 15.464 28 0 28H28V0Z" />
            </Styler>
        </Styler>
    );
};

export default function App({ Component, pageProps }: AppPropsWithLayout) {
    // Individual pages can specify a custom layout by implementing a getLayout property
    // See https://nextjs.org/docs/basic-features/layouts for more detail.
    const getLayout: GetLayout<typeof pageProps> =
        Component.getLayout ??
        ((page, props) => {
            if (isMobileAppRequest(pageProps.context?.req?.headers)) {
                return page;
            }
            return (
                <Layout masthead={<Masthead />} footer={<Footer />} main={main} {...props}>
                    {page}
                </Layout>
            );
        });

    useMountEffect(() => {
        const { env, service, version } = pageProps?.context?.config ?? {};
        const datadogConfig = getDataDogConfigSettings(pageProps?.configSettings);
        if (parseBoolean(datadogConfig?.HcDataDogRealUserMonitoringEnabled)) {
            loadAndInitDataDogRum({
                env,
                service,
                version,
                applicationId: datadogConfig.HcDataDogApplicationId,
                clientToken: datadogConfig.HcDataDogClientToken,
                sessionReplaySampleRate: parseInt(datadogConfig.HcDataDogSessionReplaySampleRate),
                sessionSampleRate: parseInt(datadogConfig.HcDataDogSessionSampleRate),
                trackLongTasks: parseBoolean(datadogConfig.HcDataDogTrackLongTasks),
                trackResources: parseBoolean(datadogConfig.HcDataDogTrackResources),
                trackUserInteractions: parseBoolean(datadogConfig.HcDataDogTrackUserInteractions),
            });
        }
    });

    // Must be called before any other initializeApollo call (inc. that via initialAppState) to ensure userContext is added to apolloClient singleton
    // TODO: Refactor initialAppState to resolve this flakiness
    const apolloClient = useApollo(pageProps);

    const query = pageProps.context?.query || null;

    if (query) {
        setFeatureFlags(pageProps.configSettings, query);
    }

    // Checks the query params and sets up the reactive vars, must happen client-side
    useEffect(() => {
        initialAppState(query, searchCreatedRouting, priceDisplayModeToggleRouting);
    }, [query]);

    const { setIsPageRoutingState } = usePageRouting();
    useEffect(() => {
        addRouterEventListener('routeChangeStart', () => {
            setIsPageRoutingState(true);
        });
        addRouterEventListener('routeChangeComplete', (url: string) => {
            trackPageViewClient(url);
            setIsPageRoutingState(false);
        });
        addRouterEventListener('routeChangeError', () => {
            setIsPageRoutingState(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isMobile = pageProps?.deviceDetection?.isMobile;
    const isTablet = pageProps?.deviceDetection?.isTablet;
    const ssrPredictedWidth = isTablet ? 768 : isMobile ? 375 : 1024;

    const isMobileApp = !!isMobileAppRequest(pageProps.context?.req?.headers);
    const currentContext = { ...pageProps.currentContext, isMobileApp };
    const correlationId = useMemo(() => {
        return (
            pageProps.correlationId ??
            pageProps.context?.req.headers['x-trp-correlation-id'] ??
            pageProps.context?.res.getHeader('x-trp-correlation-id')
        );
    }, [pageProps.context?.req.headers, pageProps.context?.res, pageProps.correlationId]);

    if (typeof window === 'undefined') {
        trackPageViewServer(pageProps);
    }

    useMountEffect(() => {
        sessionStorage.setItem('placesSession', v4());
    });

    return (
        <GlobalProvider
            resourceStrings={pageProps.resourceStrings}
            configSettings={pageProps.configSettings}
            globalContents={pageProps.globalContents}
            currentContext={currentContext}
            deviceInfo={pageProps.deviceDetection}
        >
            <HoneycombThemeProvider
                theme={TRAVEL_REPUBLIC_THEME_OVERRIDES}
                customStyleProps={TRAVEL_REPUBLIC_CUSTOM_STYLE_PROPS}
                cultureCode={pageProps.currentContext?.cultureCode}
            >
                <BreakpointProvider ssrPredictedWidth={ssrPredictedWidth}>
                    <ApolloProvider client={apolloClient}>
                        <Head>
                            {/* https://nextjs.org/docs/messages/no-document-viewport-meta */}
                            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                        </Head>

                        <LazyImageProvider>
                            <GoogleTagManagerNoScript />
                            {getLayout(
                                <ErrorBoundary
                                    resetKeys={[pageProps.context?.req.url]}
                                    fallbackRender={errorFallback(correlationId)}
                                >
                                    <GoogleTagManager />
                                    <Platform195 {...platform195Props} />
                                    <Webtrends path={pageProps.context?.req.url} />
                                    <Component {...pageProps} />
                                    <Modal />
                                </ErrorBoundary>,
                                pageProps
                            )}
                        </LazyImageProvider>
                    </ApolloProvider>
                </BreakpointProvider>
            </HoneycombThemeProvider>
        </GlobalProvider>
    );
}

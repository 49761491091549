import * as React from 'react';
import { Styler } from '@honeycomb/ui-core';
import { TravelRepublicLogo } from './images/logos/TravelRepublicLogo';
import { TravelRepublicLogoStacked } from './images/logos/TravelRepublicLogoStacked';

export function Logo(): React.JSX.Element {
    return (
        <>
            <Styler display={{ m: 'none' }}>
                <TravelRepublicLogoStacked h={28} va="middle" />
            </Styler>
            <Styler display={{ xs: 'none', m: 'block' }}>
                <TravelRepublicLogo h={26} va="middle" />
            </Styler>
        </>
    );
}

import { getGraphqlUrl } from '@honeycomb/data';
export function trackPageView({ url, referer, host, trpUTM, userAgent, }) {
    const TRACK_PAGE_VIEW_MUTATION = `mutation TrackPageView($pageViewRequest: TrackPageViewRequest!) {
        trackPageView(pageViewRequest: $pageViewRequest)
    }`;
    const graphqlUrl = `${getGraphqlUrl()}/trackpageview`;
    fetch(graphqlUrl, {
        method: 'POST',
        headers: Object.assign(Object.assign({ 'Content-Type': 'application/json', 'x-original-origin': host }, (userAgent ? { 'user-agent': userAgent } : {})), (trpUTM ? { cookie: `trpUTM=${trpUTM}` } : {})),
        body: JSON.stringify({
            query: TRACK_PAGE_VIEW_MUTATION,
            variables: {
                pageViewRequest: { referer, url },
            },
        }),
    }).catch((error) => console.error(error));
}
export function trackPageViewClient(url) {
    const fullUrl = `${window.location.protocol}//${window.location.host}${url}`;
    const referer = document.referrer;
    trackPageView({ url: fullUrl, referer, host: window.location.host });
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function trackPageViewServer(pageProps) {
    var _a, _b, _c, _d, _e;
    if ((_b = (_a = pageProps === null || pageProps === void 0 ? void 0 : pageProps.context) === null || _a === void 0 ? void 0 : _a.req) === null || _b === void 0 ? void 0 : _b.headers) {
        const { referer, host, cookie, 'x-forwarded-proto': xForwardedProto, 'user-agent': userAgent, } = pageProps.context.req.headers;
        // Not sure what the best way to get the accurate protocol is here in the absence of Next13 middleware
        const protocol = xForwardedProto || (referer ? new URL(referer).protocol.slice(0, -1) : 'https');
        const url = `${protocol}://${host}${pageProps.context.req.url}`;
        const trpUTM = (_e = (_d = (_c = cookie === null || cookie === void 0 ? void 0 : cookie.split(';')) === null || _c === void 0 ? void 0 : _c.map((c) => c.trim().split('='))) === null || _d === void 0 ? void 0 : _d.find(([name]) => name === 'trpUTM')) === null || _e === void 0 ? void 0 : _e[1];
        // When the trpUTM cookie is not set (on the initial call) then track-site-visit middleware will catch this case
        // Once we upgrade to Next13 middleware then we can set request headers and should be able to pass both the cookie
        // and the referer value to this function which should handle all cases.
        if (trpUTM) {
            trackPageView({ url, referer, trpUTM, userAgent, host });
        }
    }
}

import { FeatureFlagEnum, hasFeatureFlag, MainMenu, useGlobalContext } from '@honeycomb/data';
import {
    Container,
    FlexGrid,
    Header,
    MenuIcon,
    Stacker,
    ActionButton,
    Divider,
    Anchor,
    PhoneIcon,
    Styler,
    HelpIcon,
    HeartIcon,
    useDisclosure,
} from '@honeycomb/ui-core';
import { AccountMenuTrigger, NavigationMenu, parseBoolean } from '@honeycomb/ui';

import { Logo } from './Logo';
import { MastheadAtolLink } from './MastheadAtolLink';

export type MastheadProps = {
    reduced?: boolean;
};

// We need the account button to have a consistent width and reserve that space while it is loading,
// otherwise we negatively impact Largest Contentful Paint due to the shifting of neighbouring content.
const ACCOUNT_BUTTON_WIDTH = { xs: 'auto', m: 106 }; // Width must accommodate the longest caption ("My account")

export function Masthead({ reduced = false }: MastheadProps) {
    const [menuOpen, { open: handleOpenMenu, close: handleCloseMenu }] = useDisclosure(false);

    const {
        resourceStrings: {
            TELEPHONENUMBER,
            HC_SUPPORTCENTREURL,
            MAINMENU_HEADING,
            HC_SUPPORTCENTRE_LABEL,
            HC_WISHLIST_LABEL,
        },
        globalContents,
        configSettings: { ShowAtolLogo, EnableShortlist },
    } = useGlobalContext();

    const { HEADER } = globalContents;
    const { content: menuData } = HEADER || {};
    const phoneHref = `tel:${TELEPHONENUMBER.replace(/\s/g, '')}`;
    const shortlistEnabled = parseBoolean(EnableShortlist) || hasFeatureFlag(FeatureFlagEnum.SHORTLIST);

    return (
        <>
            <Header component="div" disableGutters={true}>
                <Container>
                    <FlexGrid container={true} alignItems="center" flexWrap="nowrap">
                        <FlexGrid xs="hold">
                            {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                            <a href="/">
                                <Logo />
                            </a>
                        </FlexGrid>
                        {!reduced && (
                            <FlexGrid xs="fill">
                                <Stacker
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="flex-end"
                                    spacing={{ xs: 2, m: 5 }}
                                >
                                    {parseBoolean(ShowAtolLogo) && <MastheadAtolLink />}

                                    <Styler display={{ xs: 'none', m: 'block' }}>
                                        <Anchor weight="semiBold" color="accent.base" href={phoneHref} underline="none">
                                            {TELEPHONENUMBER}
                                        </Anchor>
                                    </Styler>

                                    <Styler display={{ m: 'none' }}>
                                        <ActionButton
                                            href={phoneHref}
                                            target="_new"
                                            icon={<PhoneIcon />}
                                            aria-label="Call us"
                                        />
                                    </Styler>

                                    <AccountMenuTrigger hideIcon={{ m: true }} w={ACCOUNT_BUTTON_WIDTH} />

                                    {shortlistEnabled && (
                                        <ActionButton
                                            href="/wishlist"
                                            icon={<HeartIcon />}
                                            aria-label={HC_WISHLIST_LABEL}
                                            hideLabel={{ xs: true, m: false }}
                                        >
                                            {HC_WISHLIST_LABEL}
                                        </ActionButton>
                                    )}

                                    <ActionButton
                                        href={HC_SUPPORTCENTREURL}
                                        target="_new"
                                        icon={<HelpIcon />}
                                        aria-label={HC_SUPPORTCENTRE_LABEL}
                                        hideLabel={{ xs: true, m: false }}
                                        hideIcon={{ m: true }}
                                    >
                                        {HC_SUPPORTCENTRE_LABEL}
                                    </ActionButton>

                                    <ActionButton icon={<MenuIcon />} onClick={handleOpenMenu} aria-label="Menu" />
                                </Stacker>
                            </FlexGrid>
                        )}
                    </FlexGrid>
                </Container>
                <NavigationMenu
                    menuData={(menuData as MainMenu) ?? undefined}
                    title={MAINMENU_HEADING}
                    open={menuOpen}
                    onClose={handleCloseMenu}
                />
            </Header>
            <Divider />
        </>
    );
}

import React from 'react';
import { Styler, useDisclosure } from '@honeycomb/ui-core';
import { AtolDialog } from './AtolDialog';
import { TravelRepublicAtolLogo } from './images/logos/TravelRepublicAtolLogo';

export function MastheadAtolLink(): React.JSX.Element {
    const [dialogOpen, { open: handleOpenDialog, close: handleCloseDialog }] = useDisclosure(false);

    return (
        <>
            <Styler display={{ xs: 'none', m: 'block' }} button={true} onClick={handleOpenDialog}>
                <TravelRepublicAtolLogo w={36} h={36} va="middle" fill="primary.base" />
            </Styler>
            <AtolDialog open={dialogOpen} onClose={handleCloseDialog} />
        </>
    );
}

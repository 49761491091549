import * as React from 'react';
import { Styler, StylerProps } from '@honeycomb/ui-core';

export function TravelRepublicAtolLogo(props: StylerProps): React.JSX.Element {
    return (
        <Styler component="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            <path d="M12.892 2.892l-0.009-0.002-0.628 0.17-0.020-0.551 0.869-0.227 0.587 0.106-0.548 3.023-0.685-0.124 0.433-2.395z" />
            <path d="M16.65 4.47c-0.169 0.935-0.662 1.508-1.436 1.368-0.783-0.141-1-0.906-0.85-1.763 0.159-0.874 0.657-1.499 1.439-1.358 0.81 0.147 0.996 0.925 0.847 1.753zM15.076 4.205c-0.13 0.692-0.022 1.053 0.253 1.102s0.487-0.267 0.611-0.955c0.121-0.67 0.041-1.049-0.244-1.101-0.26-0.047-0.497 0.251-0.62 0.954z" />
            <path d="M19.185 3.982l-1.174-0.212-0.149 0.453c0.067 0.002 0.123 0.012 0.197 0.026 0.289 0.052 0.571 0.17 0.756 0.363 0.201 0.195 0.295 0.471 0.231 0.824-0.102 0.563-0.676 0.972-1.492 0.825-0.368-0.067-0.661-0.206-0.812-0.325l0.222-0.507c0.123 0.090 0.383 0.223 0.658 0.273 0.293 0.053 0.631-0.031 0.689-0.351 0.056-0.312-0.156-0.546-0.761-0.656-0.168-0.031-0.286-0.042-0.414-0.046l0.487-1.55 1.668 0.302-0.105 0.581z" />
            <path d="M19.4 5.726c0.066-0.363 0.321-0.575 0.678-0.66l0.002-0.014c-0.266-0.202-0.366-0.475-0.318-0.739 0.093-0.511 0.616-0.768 1.221-0.658 0.708 0.128 0.927 0.59 0.861 0.957-0.047 0.261-0.233 0.491-0.558 0.586l-0.003 0.014c0.273 0.164 0.488 0.463 0.418 0.853-0.1 0.549-0.627 0.833-1.331 0.706-0.767-0.139-1.044-0.636-0.97-1.045zM20.97 5.986c0.048-0.265-0.119-0.458-0.385-0.584-0.244 0.023-0.41 0.166-0.45 0.384-0.044 0.218 0.087 0.453 0.352 0.501 0.252 0.046 0.444-0.087 0.483-0.301zM20.433 4.41c-0.037 0.204 0.126 0.369 0.354 0.487 0.172-0.017 0.343-0.139 0.378-0.33 0.034-0.186-0.040-0.392-0.301-0.439-0.242-0.043-0.397 0.092-0.431 0.282z" />
            <path d="M23.224 4.761l-0.009-0.002-0.627 0.17-0.021-0.551 0.869-0.227 0.587 0.106-0.547 3.022-0.685-0.124 0.433-2.394z" />
            <path d="M22.19 18.174c0 0-3.652-0.046-5.465-3.658-2.506-4.99 2.705-7.672 2.705-7.672l4.626 0.823-1.866 10.507z" />
            <path d="M31.648 18.752v0zM13.418 30.626c-8.071-1.423-13.479-9.146-12.057-17.214 1.424-8.067 9.148-13.474 17.22-12.051 8.070 1.423 13.478 9.144 12.056 17.213-1.423 8.067-9.148 13.475-17.219 12.052zM0.352 13.236c-1.52 8.624 4.261 16.878 12.889 18.4 8.629 1.52 16.886-3.913 18.408-12.538 0.162-0.928 0.243-2.199 0.243-3.112 0-7.559-5.433-14.276-13.132-15.633-8.628-1.522-16.886 4.258-18.408 12.883z" />
            <path d="M4.090 19.164c0.033 0.104 0.067 0.211 0.119 0.304 0.17 0.286 0.472 0.327 0.762 0.248 0.382-0.105 0.58-0.368 0.512-0.615l-0.080-0.296-1.313 0.359zM2.397 19.627c-0.023-0.123-0.041-0.244-0.074-0.364-0.032-0.119-0.079-0.234-0.121-0.35l3.263-0.891 0.319 1.17c0.181 0.661 0.012 1.090-0.526 1.238-0.847 0.231-1.197-0.338-1.43-1.195l-1.431 0.391z" />
            <path d="M5.271 22.096c0.221 0.472 0.563 0.568 1 0.311 0.061-0.035 0.121-0.077 0.201-0.148 0.313-0.274 0.092-0.573-0.061-0.834l-1.14 0.67zM6.267 20.654l0.532 0.906c0.118 0.201 0.307 0.505 0.319 0.739 0.014 0.316-0.102 0.557-0.392 0.726-0.416 0.246-0.837 0.133-1.154-0.214-0.271 0.475-0.507 0.967-0.754 1.455l-0.167 0.319c-0.066-0.132-0.128-0.266-0.204-0.398-0.075-0.128-0.163-0.248-0.246-0.372l0.596-1.030c0.077-0.131 0.203-0.364 0.31-0.554l-0.018-0.028-1.365 0.802c-0.054-0.112-0.104-0.225-0.166-0.332-0.063-0.106-0.139-0.204-0.209-0.306l2.917-1.712z" />
            <path d="M7.053 24.99c-0.422 0.482-0.651 1.185-0.094 1.673 0.571 0.501 1.327 0.153 1.867-0.462 0.418-0.479 0.676-1.222 0.12-1.711-0.516-0.451-1.165-0.331-1.893 0.501zM6.744 26.869c-0.819-0.718-0.95-1.683-0.212-2.526 0.646-0.738 1.707-0.865 2.631-0.055 1.058 0.925 0.717 1.944 0.217 2.523-0.63 0.726-1.725 0.856-2.635 0.058z" />
            <path d="M10.877 29.349c-0.109-0.058-0.216-0.121-0.329-0.169-0.114-0.051-0.232-0.086-0.349-0.125l1.204-2.783c-0.321-0.139-0.62-0.23-0.869-0.3 0.036-0.061 0.077-0.118 0.106-0.185 0.028-0.065 0.042-0.132 0.061-0.201l2.33 1.008c-0.037 0.060-0.077 0.118-0.105 0.182-0.030 0.067-0.043 0.138-0.061 0.203-0.253-0.147-0.507-0.293-0.782-0.413l-1.205 2.784z" />
            <path d="M15.384 27.003c-0.022 0.065-0.048 0.136-0.060 0.205s-0.010 0.144-0.012 0.212c-0.291-0.089-0.615-0.184-1.112-0.258l-0.177 1.045 0.505 0.086c0.219 0.037 0.421 0.052 0.605 0.057-0.021 0.067-0.048 0.138-0.060 0.204-0.012 0.069-0.009 0.145-0.012 0.214-0.175-0.055-0.371-0.108-0.59-0.145l-0.505-0.086-0.209 1.234c0.496 0.078 0.831 0.111 1.133 0.122-0.021 0.067-0.048 0.138-0.060 0.206-0.012 0.067-0.009 0.142-0.012 0.213l-1.852-0.315 0.563-3.31 1.852 0.314z" />
            <path d="M18.915 29.595l-0.067 0.432c-0.279 0.181-0.622 0.275-0.951 0.306-1.052 0.101-1.908-0.47-2.006-1.474-0.124-1.283 0.703-1.951 1.771-2.056 0.325-0.030 0.726 0.014 1.025 0.144-0.027 0.17-0.041 0.341-0.062 0.517l-0.070 0.006c-0.208-0.247-0.54-0.404-0.858-0.372-0.815 0.079-1.069 0.891-0.999 1.614 0.081 0.839 0.551 1.387 1.306 1.313 0.306-0.028 0.643-0.221 0.843-0.453l0.068 0.023z" />
            <path d="M21.473 28.93c-0.117 0.035-0.236 0.066-0.349 0.111s-0.221 0.104-0.332 0.157l-1.094-2.792c-0.323 0.126-0.597 0.271-0.82 0.395-0.016-0.067-0.029-0.137-0.055-0.205-0.024-0.063-0.062-0.121-0.099-0.182l2.339-0.917c0.015 0.067 0.028 0.135 0.052 0.199 0.026 0.069 0.066 0.127 0.1 0.185-0.281 0.074-0.561 0.146-0.837 0.255l1.094 2.793z" />
            <path d="M22.999 24.145c0.032 0.061 0.064 0.13 0.101 0.184 0.039 0.056 0.095 0.108 0.14 0.158-0.265 0.139-0.558 0.299-0.958 0.593l0.603 0.857 0.414-0.292c0.178-0.127 0.331-0.258 0.465-0.382 0.031 0.061 0.062 0.129 0.101 0.185s0.093 0.106 0.141 0.157c-0.162 0.084-0.338 0.183-0.515 0.31l-0.415 0.293 0.714 1.012c0.404-0.291 0.659-0.502 0.881-0.707 0.032 0.063 0.061 0.131 0.101 0.185 0.040 0.057 0.094 0.108 0.14 0.159l-1.518 1.070-1.915-2.712 1.518-1.071z" />
            <path d="M26.322 25.091l0.256-0.326c0.417-0.528 0.471-1.137-0.425-1.846-0.72-0.569-1.271-0.453-1.698 0.087l-0.297 0.375 2.163 1.709zM24.517 22.472c0.679-0.795 1.514-0.681 2.143-0.184 0.864 0.684 0.896 1.697 0.276 2.48l-0.846 1.072-2.606-2.061 1.034-1.307z" />
            <path d="M10.589 19.536l0.79 0.141-0.278-0.686z" />
            <path d="M14.838 16.266c-3.047-3.182-3.071-6.963-1.159-10.422l-2.538-0.451-2.833 16.080 12.892 2.269 0.853-4.804c0-0.001-4.167 0.51-7.216-2.671zM11.655 20.336l-0.142-0.356-1.149-0.205-0.267 0.284-0.68-0.12 1.387-1.371 0.734 0.129 0.822 1.764-0.705-0.125zM15.239 19.642l-0.93-0.164-0.229 1.289-0.653-0.116 0.229-1.29-0.934-0.167 0.050-0.277 2.516 0.447-0.049 0.277zM18.386 20.726c-0.050 0.281-0.244 0.469-0.585 0.563-0.256 0.081-0.605 0.081-1.050 0.003-0.444-0.079-0.772-0.199-0.985-0.364-0.287-0.204-0.407-0.448-0.358-0.731 0.051-0.286 0.248-0.475 0.588-0.562 0.257-0.081 0.607-0.081 1.050-0.003s0.774 0.201 0.986 0.364c0.287 0.199 0.405 0.443 0.355 0.73zM20.802 21.96l-2.188-0.39 0.28-1.567 0.648 0.115-0.229 1.287 1.538 0.273-0.049 0.283z" />
            <path d="M16.996 19.91c-0.256-0.046-0.471-0.034-0.641 0.034s-0.272 0.191-0.302 0.367c-0.032 0.178 0.021 0.327 0.157 0.45s0.332 0.207 0.59 0.254c0.256 0.045 0.47 0.033 0.638-0.036 0.169-0.070 0.268-0.192 0.299-0.368s-0.020-0.326-0.154-0.449c-0.134-0.123-0.329-0.208-0.587-0.252z" />
        </Styler>
    );
}

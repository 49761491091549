import React from 'react';
import Head from 'next/head';
import { parseBoolean, useGlobalContext } from '@honeycomb/data';
export function Webtrends({ path }) {
    const { configSettings: { WebTrendsExcludedPages, WebtrendsAccountId, WebtrendsEnabled } } = useGlobalContext();
    const isDev = process.env.NODE_ENV === 'development';
    const isEnabled = !isDev && parseBoolean(WebtrendsEnabled) && Boolean(WebtrendsAccountId);
    if (!isEnabled) {
        return null;
    }
    ;
    const excludedPages = (WebTrendsExcludedPages === null || WebTrendsExcludedPages === void 0 ? void 0 : WebTrendsExcludedPages.split('|')) || [];
    const isExcluded = excludedPages.length > 0 && excludedPages.some((arrPath) => (arrPath === 'home' && path === '/') || (path === null || path === void 0 ? void 0 : path.includes(arrPath)));
    if (isExcluded) {
        return null;
    }
    return (React.createElement(Head, null,
        React.createElement("script", { dangerouslySetInnerHTML: {
                __html: `!function(e){e.getElementsByTagName("head")[0];var t={add:function(t,n){t instanceof Array&&(t=t.join(" "));var a=e.getElementsByTagName("head")[0],d=e.createElement("style");d.type="text/css",n&&(d.id=n),d.styleSheet?d.styleSheet.cssText=t:d.appendChild(e.createTextNode(t)),a.appendChild(d)},del:function(t){var n=e.getElementById(t);n&&n.parentNode.removeChild(n)}},n="wto_taghide";t.add("body { opacity: 0.00000001 !important; }",n),setTimeout(function(){t.del(n),window.wto_aborted=!0},3e3);var a=e.createElement("script");a.src="//c.webtrends-optimize.com/acs/accounts/${WebtrendsAccountId}/js/wt.js",a.onerror=function(){t.del(n)},a.onload=function(){t.del(n)},e.getElementsByTagName("head")[0].appendChild(a)}(document);`,
            } })));
}

import { useGlobalContext } from '@honeycomb/data';
import Head from 'next/head';
import React from 'react';
export var Platform195Brand;
(function (Platform195Brand) {
    Platform195Brand["TravelRepublic"] = "TRAVREP";
    Platform195Brand["NetFlights"] = "NF";
})(Platform195Brand || (Platform195Brand = {}));
export function getSizeArrayString(sizeArray) {
    return (sizeArray === null || sizeArray === void 0 ? void 0 : sizeArray.map((size) => `[${size}]`).join(',')) || '';
}
export function platform195Setup(platform195Id, sizeArray, adSlots, platform195Brand) {
    const mappingString = sizeArray
        ? `var mapping1 = googletag.sizeMapping()${sizeArray
            .map((element) => `.addSize([${element.screenSize}], [${getSizeArrayString(element.adSize)}])`)
            .join('')}.build();`
        : '';
    const slotsString = (adSlots === null || adSlots === void 0 ? void 0 : adSlots.map((element) => `googletag.defineSlot('/${platform195Id}/${platform195Brand}/${element.name}', [${getSizeArrayString(element.sizes)}], '${element.id}')
                .defineSizeMapping(mapping1)
                .addService(googletag.pubads());
        `).join('')) || '';
    return `
        window.googletag = window.googletag || {cmd: []};
        googletag.cmd.push(function() {
            ${mappingString}
            ${slotsString}
            googletag.pubads().enableSingleRequest();
        });
    `;
}
export function Platform195({ sizeArray, adSlots, platform195Brand }) {
    const { configSettings: { Platform195EnabledPages, Platform195AccountId }, } = useGlobalContext();
    if (!Platform195EnabledPages)
        return null;
    return (React.createElement(Head, null,
        React.createElement("script", { async: true, src: "//securepubads.g.doubleclick.net/tag/js/gpt.js" }),
        React.createElement("script", { dangerouslySetInnerHTML: {
                __html: platform195Setup(Platform195AccountId, sizeArray, adSlots, platform195Brand),
            } })));
}

import { Styler, StylerProps } from '@honeycomb/ui-core';
import * as React from 'react';

export function TravelRepublicLogo(props: StylerProps): React.JSX.Element {
    return (
        <Styler component="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1522.09 226.67" {...props}>
            <g>
                <path
                    fill="#ff5c00"
                    d="m0,0v226.67h704.8V0H0Zm130.19,58.7h-30.22v141.81h-34.87V58.7h-30.22V26.15h95.32v32.55Zm82.98,141.81l-16.04-59.98h-14.41v59.98h-34.87V26.15h49.29c29.76,0,49.52,13.95,49.52,48.82v16.74c0,18.6-6.28,31.38-16.74,39.29l20.69,69.51h-37.43Zm117.06,0l-3.02-23.48h-27.43l-3.02,23.48h-36.96l32.31-174.36h44.64l32.55,174.36h-39.06Zm110.72,0h-44.4l-32.55-174.36h39.06l16.51,129.26,16.74-129.26h37.2l-32.55,174.36Zm127.7,0h-81.83V26.15h81.6v32.55h-46.73v35.57h38.36v32.55h-38.36v41.15h46.96v32.55Zm101.28,0h-80.21V26.15h34.87v141.81h45.33v32.55Z"
                />
                <polygon fill="#ff5c00" points="303.49 149.14 323.71 149.14 313.49 70.33 303.49 149.14" />
                <path
                    fill="#ff5c00"
                    d="m197.83,58.7h-15.11v49.29h15.11c9.3,0,13.72-4.65,13.72-16.04v-17.2c0-11.39-4.42-16.04-13.72-16.04Z"
                />
            </g>
            <g>
                <path
                    fill="#0b3c80"
                    d="m788.96,140.54h-14.41v59.98h-34.87V26.15h49.29c29.76,0,49.52,13.95,49.52,48.82v16.74c0,18.6-6.28,31.38-16.74,39.29l20.69,69.51h-37.43l-16.04-59.98Zm-14.41-32.55h15.11c9.3,0,13.72-4.65,13.72-16.04v-17.2c0-11.39-4.42-16.04-13.72-16.04h-15.11v49.29Z"
                />
                <path
                    fill="#0b3c80"
                    d="m934.01,167.97v32.55h-81.83V26.15h81.6v32.55h-46.73v35.57h38.36v32.55h-38.36v41.15h46.96Z"
                />
                <path
                    fill="#0b3c80"
                    d="m1044.53,78.93v4.65c0,38.13-19.53,52.54-49.98,52.54h-12.79v64.4h-34.87V26.15h47.66c31.38,0,49.98,14.65,49.98,52.77Zm-35.1-.23c0-15.34-4.88-19.99-14.18-19.99h-13.48v44.87h13.48c9.3,0,14.18-4.42,14.18-19.76v-5.12Z"
                />
                <path
                    fill="#0b3c80"
                    d="m1151.46,26.15v127.63c0,29.76-20.46,48.82-49.98,48.82s-50.22-19.06-50.22-48.82V26.15h34.87v127.63c0,9.76,6.28,16.27,15.34,16.27s15.11-6.51,15.11-16.27V26.15h34.87Z"
                />
                <path
                    fill="#0b3c80"
                    d="m1262.68,140.3v19.06c0,24.41-15.81,41.15-43.71,41.15h-54.63V26.15h53.01c27.67,0,43.71,16.74,43.71,41.38v14.65c0,12.32-4.18,21.85-11.86,28.36,8.6,6.28,13.48,16.51,13.48,29.76Zm-63.47-81.6v36.27h16.04c7.44,0,10.69-3.49,10.69-11.39v-13.48c0-7.9-3.25-11.39-10.69-11.39h-16.04Zm28.36,79.97c0-7.67-3.02-11.16-10.46-11.16h-17.9v40.45h17.9c7.44,0,10.46-3.49,10.46-11.39v-17.9Z"
                />
                <path fill="#0b3c80" d="m1309.99,167.97h45.33v32.55h-80.21V26.15h34.87v141.81Z" />
                <path fill="#0b3c80" d="m1404.98,200.52h-34.87V26.15h34.87v174.36Z" />
                <path
                    fill="#0b3c80"
                    d="m1522.09,72.19v23.71h-34.87v-23.25c0-9.76-6.28-16.27-15.11-16.27s-15.34,6.51-15.34,16.27v81.37c0,9.76,6.28,16.27,15.34,16.27s15.11-6.51,15.11-16.27v-25.11h34.87v25.11c0,29.76-20.46,48.82-49.98,48.82s-50.22-19.06-50.22-48.82v-81.83c0-29.53,20.69-48.36,50.22-48.36s49.75,18.83,49.98,48.36Z"
                />
            </g>
        </Styler>
    );
}
